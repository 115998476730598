import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

// Ngx
import { CookieService } from 'ngx-cookie-service';

// Auth Service
import { CheckLoginAuthService } from '../../core/guards';

@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styleUrls: ['./pagenotfound.component.scss']
})

export class PageNotFoundComponent implements OnInit {

  constructor(private checkLogin: CheckLoginAuthService,
    private cookieService: CookieService,
    private router: Router) { }

  ngOnInit() {
  }

  // Used for navigate user to proper location
  backtohome() {
    let data = JSON.parse(localStorage.getItem('loggedData'));
    if (data) {
      console.log([data.routerName + '/dash']);
      this.router.navigate([data.routerName + '/dash']);
      return false;
    }
    else {
      if (this.cookieService.get('urlString')) {
        this.router.navigate([this.cookieService.get('urlString') + '/login']);
        return false;
      }
      else {
        this.router.navigate(['/login']);
        return false;
      }
    }
  }
}
