import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

// Angular Material
import { MatSnackBar } from '@angular/material/snack-bar';

// Ngx
import { NgxSpinnerService } from 'ngx-spinner';
import { CookieService } from 'ngx-cookie-service';

// Api and Auth Service
import { CheckLoginAuthService } from '../../core/guards';
import { AppService } from '../../services';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  formSubmitted = false;
  responseData: any;
  userType: string;

  constructor(
    private router: Router, private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
    private snackbar: MatSnackBar, private activatedRoute: ActivatedRoute, private appService: AppService
  ) { }

  ngOnInit() {
    this.userType = this.router.url.split('/')[1];
    localStorage.clear();
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(5)]],
    });
  }

  // Navigate to forgot page
  gotoforgot() {
    this.router.navigate([this.userType + '/' + 'forget']);
  }

  get f() { return this.loginForm.controls; }

  // login
  onSubmit() {
    this.formSubmitted = true;
    if (this.loginForm.invalid) {
      return;

    } else {
      this.spinner.show();
      this.userType = this.router.url.split('/')[1];

      let url = 'login';
      let loginData = {
        email: this.f.email.value,
        password: this.f.password.value
      };

      this.appService.POST(url, loginData).subscribe((data) => {

        if (data.hasOwnProperty('accessToken')) {
          this.responseData = data;
          localStorage.setItem('LoggedInToken', JSON.stringify(this.responseData.accessToken));
          localStorage.setItem('loggedData', JSON.stringify(this.responseData));
          this.router.navigate(['/dashboard']);
        } else {
          this.snackbar.open('Login Succesfully', '', {
            duration: 3000, verticalPosition: 'top', panelClass: 'success-dialog'
          });
        }
        this.spinner.hide();

      }, err => {
        console.log(err);
        this.spinner.hide();
        this.snackbar.open(err.error, '', {
          duration: 3000, verticalPosition: 'top', panelClass: 'success-dialog'
        });
      });

    }
  }
}
