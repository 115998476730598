import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// Api and data service
import { AppService, DataService } from '../../services';

// Angular Material
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})


export class NavComponent implements OnInit {

  loggedinData: any;
  showNav = false;

  constructor(private router: Router, private appService: AppService,
    private dataService: DataService, private snackbar: MatSnackBar) { }

  ngOnInit() {
    this.loggedinData = JSON.parse(localStorage.getItem('loggedData'));
  }


  showNavFnc() {
    if (this.showNav) {
      this.showNav = false;
    } else {
      this.showNav = true;
    }
  }

  // logout for dashboard component
  logout() {
    this.router.navigate(['/login']);
    localStorage.clear();
  }

}
