import { Injectable } from '@angular/core';
import { STATES,ROLES } from 'src/states';
import { State,Role } from 'src/state.interface';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  getStates(): State[]{
    return STATES;
  }

  getRole(): Role[]{
    return ROLES;
  }
  constructor() { }
}
 
