import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CheckLoginAuthService } from './check-login-auth.service';

@Injectable()
export class LoginGuardService implements CanActivate {
  constructor(private checkLogin: CheckLoginAuthService,
              private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.checkLogin.isAuthenticated()) {
      console.log('called login guard');
      return true;
    } else {
      let data = JSON.parse(localStorage.getItem('loggedData'));
      this.router.navigate(['admin/dash']);
    }
  }
}
