import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

// Ngx
import { NgxSpinnerService } from 'ngx-spinner';

// Angular Material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

// Api, common Auth Service
import { CheckLoginAuthService } from '../../core/guards';
import { AppService } from '../../services';
import { CommonService } from "../../services/common.service";


@Component({
  selector: 'app-company-profile-add-team',
  templateUrl: './company-profile-add-team.component.html',
  styleUrls: ['./company-profile-add-team.component.scss']
})

export class CompanyProfileAddTeamComponent implements OnInit {

  addUserForm: FormGroup;
  itemCount = 1;
  namesArray: any = [];
  formSubmitted = false;
  showNav = false;
  viewForm = false;
  editForm = false;
  roles: any = [];
  plan: any = 'Business';
  teammemberInfo: any = {};
  teammemberInfoPresent: any = {};

  constructor(private checkLogin: CheckLoginAuthService, private appService: AppService, private commonService: CommonService,
    private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
    public dialog: MatDialog, private router: Router,
    private snackbar: MatSnackBar) { }

  ngOnInit() {
    this.roles = this.commonService.getRole();
    this.addUserForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern(/^[a-zA-Z ]+$/), Validators.maxLength(80)]],
      email: ['', [Validators.required, Validators.email]],
      role: ['', [Validators.required]]
    });
    if (this.plan === 'Business') {
      while (this.itemCount < 5) {
        this.addMoreItem();
      }
    }
    if (this.plan === 'Enterprise') {
      while (this.itemCount < 10) {
        this.addMoreItem();
      }
    }
  }

  // Convenience getter for easy access to form feild
  get f() { return this.addUserForm.controls; }

  // Used for navbar toggler
  showNavFnc() {
    if (this.showNav) {
      this.showNav = false;
    } else {
      this.showNav = true;
    }
  }

  // this add controls on form for add new team member
  addMoreItem() {
    if (this.plan === 'Business' && this.itemCount < 5) {
      this.itemCount++
      this.namesArray.push({
        nameId: 'name' + this.itemCount,
        emailId: 'email' + this.itemCount,
        roleId: 'role' + this.itemCount,
        name: '',
        email: '',
        role: '',
        //present used for check info entered or not in feilds.if info is entered then it becomes true and add checkmark in sidebar.
        present: false,
        item: 'Team Member ' + this.itemCount,
        value: ''
      });
      this.addUserForm.addControl('name' + this.itemCount + '', new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z ]+$/), Validators.maxLength(80)]));
      this.addUserForm.addControl('email' + this.itemCount + '', new FormControl('', [Validators.required, Validators.email]));
      this.addUserForm.addControl('role' + this.itemCount + '', new FormControl('', Validators.required));
    } else if (this.plan === 'Enterprise' && this.itemCount < 10) {
      this.itemCount++
      this.namesArray.push({
        nameId: 'name' + this.itemCount,
        emailId: 'email' + this.itemCount,
        roleId: 'role' + this.itemCount,
        name: '',
        email: '',
        role: '',
        //present used for check info entered or not in feilds.if info is entered then it becomes true and add checkmark in sidebar. 
        present: false,
        item: 'Team Member ' + this.itemCount,
        value: ''
      });
      this.addUserForm.addControl('name' + this.itemCount + '', new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z ]+$/), Validators.maxLength(80)]));
      this.addUserForm.addControl('email' + this.itemCount + '', new FormControl('', [Validators.required, Validators.email]));
      this.addUserForm.addControl('role' + this.itemCount + '', new FormControl('', Validators.required));
    } else {
      this.snackbar.open('You Cannot add more teammember', '', {
        duration: 3000, verticalPosition: 'top', panelClass: 'success-dialog'
      });
    }
  }

  removeItems() {
    this.namesArray.pop({
      nameId: 'name' + this.itemCount,
      emailId: 'email' + this.itemCount,
      roleId: 'role' + this.itemCount,
      value: ''
    })
    this.addUserForm.removeControl('name' + this.itemCount + '');
    this.addUserForm.removeControl('email' + this.itemCount + '');
    this.addUserForm.removeControl('role' + this.itemCount + '');
    this.itemCount--
  }


  // Used for checking textfeild is filled or not. if its filled then green tick mark is appeared in nav bar in front of that feild name
  dataEntered() {
    if ((this.teammemberInfo.name !== undefined && this.teammemberInfo.name.length > 0)
      && (this.teammemberInfo.email !== undefined && this.teammemberInfo.email.length > 0)
      && (this.teammemberInfo.role !== undefined)) {
      this.teammemberInfoPresent.teammember = true;
    } else {
      this.teammemberInfoPresent.teammember = false;
    }

    for (let data of this.namesArray) {
      if ((data.name !== undefined && data.name.length > 0)
        && (data.email !== undefined && data.email.length > 0)
        && (data.role !== undefined && data.role.length > 0)) {
        data.present = true;
      } else {
        data.present = false;
      }
    }
  }


  // Used For post data to api and check form is invalid or not. called when page dot click event invoked
  onAdd() {
    this.formSubmitted = true;
    let membersObj: any = [{ name: '', email: '', role: '' }];
    let formData = this.addUserForm.value;;
    console.log(formData);
    for (let key in formData) {
      if (key === 'name') {
        membersObj[0].name = formData[key];
      } else if (key === 'email') {
        membersObj[0].email = formData[key];
      } else if (key === 'role') {
        membersObj[0].role = formData[key];
      }
    }
    if (this.addUserForm.invalid) {
      return;
    } else {
      let url = 'manage-team-members';
      let data = membersObj;
      this.appService.POST(url, data).subscribe((res: any) => {
        console.log(res);
        if (res.hasOwnProperty('createdAt')) {
          this.snackbar.open('Team members added successfully', '', {
            duration: 3000, verticalPosition: 'top', panelClass: 'success-dialog'
          });
          localStorage.setItem('companyData', JSON.stringify(res));
          this.router.navigate(['/company-loan-integration']);
        }
      },
        err => {
          if (err.status === 500) {
            this.snackbar.open(err.error, '', {
              duration: 3000, verticalPosition: 'top', panelClass: 'success-dialog'
            });
          }
        })
    }
  }
}

