import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// Api Service
import { AppService } from '../../services';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})


export class DashboardComponent implements OnInit {
  

  constructor(private router: Router, private appService: AppService) { }

  totalCompaniesCount: number;
  totalUsersCount: number;
  loggedInData: any = JSON.parse(localStorage.getItem('loggedData'));

  ngOnInit() {
    if (this.loggedInData == null) {
      this.router.navigate(['/login']);
    }
  }

}
