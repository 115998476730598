import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// Angular Material
import { ThemePalette } from '@angular/material/core';
@Component({
  selector: 'app-loan-integration-system',
  templateUrl: './loan-integration-system.component.html',
  styleUrls: ['./loan-integration-system.component.scss']
})

export class LoanIntegrationSystemComponent implements OnInit {
  color: ThemePalette;
  showNav = false;

  constructor(private router: Router) { }

  ngOnInit() {
  }

  // Used for navbar toggler
  showNavFnc() {
    if (this.showNav) {
      this.showNav = false;
    } else {
      this.showNav = true;
    }
  }

  // Used For post data to api and check form is invalid or not. called when page dot click event invoked
  onAdd() {
    this.router.navigate(['/loantype']);
  }
}

