import { Component, OnInit, Inject } from '@angular/core';

// Api and data service
import { AppService, DataService } from '../../services';

// Angular Material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';


export interface TableData {
  createdBy: string;
  createdOn: number;
  designation: string;
  email: string;
  firstName: string;
  gender: string;
  id: number;
  language: string;
  lastName: string;
  displayName: string;
  mobile: number;
  userType: string;
  dateOfBirth: string;
  companyName: string;
}

const TableData: TableData[] = []

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})

export class ConfirmationComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TableData, private appService: AppService,
    private snackbar: MatSnackBar, private dataService: DataService) { }

  ngOnInit() {
    console.log(this.data);
  }

  deleteOkBtn() {
    this.appService.DELETE(this.data['url']).subscribe((res: any) => {
      console.log(res);
      if (res.status === 201) {
        this.dialogRef.close();
        this.dataService.changeMessage('deleted');
        this.snackbar.open('Successfully Deleted', '', {
          duration: 3000, verticalPosition: 'top', panelClass: 'success-dialog'
        });
      }
    }, err => { console.log(err) });
  }

  deleteCancelBtn() {
    this.dataService.changeMessage('cancelConfirmation');
    this.dialogRef.close();
  }
}
