import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// Angular Material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

// NGX
import { NgxSpinnerService } from 'ngx-spinner';

// Auth
import { CheckLoginAuthService } from '../../core/guards';

// API and Common Service
import { AppService } from '../../services';
import { CommonService } from "../../services/common.service";

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})

export class CompanyComponent implements OnInit {
  addUserForm: FormGroup;
  companyInfo: any = {};
  companyInfoPresent: any = {};
  formSubmitted = false;
  showNav = false;
  viewFrom = false;
  editForm = false;
  state: any = [];
  SingedInData: any = JSON.parse(localStorage.getItem('SingedInData'));

  constructor(private checkLogin: CheckLoginAuthService, private appService: AppService, private commonService: CommonService,
    private formBuilder: FormBuilder, private spinner: NgxSpinnerService, public dialog: MatDialog,
    private router: Router, private snackbar: MatSnackBar) { }

  ngOnInit() {
    this.state = this.commonService.getStates();
    this.addUserForm = this.formBuilder.group({
      companyname: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9 ]+$/), Validators.maxLength(80)]],
      addressl1: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9 ]+$/), Validators.maxLength(80)]],
      addressl2: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9 ]+$/), Validators.maxLength(80)]],
      city: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9 ]+$/), Validators.maxLength(80)]],
      phonenumber: ['', [Validators.required, Validators.pattern(/^[0-9]+$/), Validators.maxLength(10)]],
      zipcode: ['', [Validators.required, Validators.maxLength(30)]],
      State: ['', [Validators.required]]
    });
    this.addUserForm.get('State').setValue('Illinois');
  }

  // Used for checking textfeild is filled or not. if its filled then green tick mark is appeared in nav bar in front of that feild name
  dataEntered() {
    if (this.companyInfo.name !== undefined && this.companyInfo.name.length > 0) {
      this.companyInfoPresent.name = true;
    } else {
      this.companyInfoPresent.name = false;
    }
    if ((this.companyInfo.addressl1 !== undefined && this.companyInfo.addressl1.length > 0)
      && (this.companyInfo.addressl2 !== undefined && this.companyInfo.addressl2.length > 0)
      && (this.companyInfo.city !== undefined && this.companyInfo.city.length > 0)
      && (this.companyInfo.state !== undefined)
      && (this.companyInfo.zipCode !== undefined && this.companyInfo.zipCode.length > 0)) {
      this.companyInfoPresent.address = true;
    } else {
      this.companyInfoPresent.address = false;
    }
    if (this.companyInfo.phonenumber !== undefined && this.companyInfo.phonenumber.length === 10) {
      this.companyInfoPresent.phonenumber = true;
    } else {
      this.companyInfoPresent.phonenumber = false;
    }
  }

  // Convenience getter for easy access to form feild
  get f() { return this.addUserForm.controls; }


  showNavFnc() {
    if (this.showNav) {
      this.showNav = false;
    } else {
      this.showNav = true;
    }
  }


  // Used For post data to api and check form is invalid or not. called when page dot is clicked.
  onAdd() {
    this.formSubmitted = true;
    if (this.addUserForm.invalid) {
      return;
    } else {
      let url = 'company';
      let formData = {
        name: this.addUserForm.value.companyname,
        address_line_1: this.addUserForm.value.addressl1,
        address_line_2: this.addUserForm.value.addressl2,
        city: this.addUserForm.value.city,
        state: this.addUserForm.value.State,
        zip_code: this.addUserForm.value.zipcode,
        user_id: this.SingedInData.user
      }

      this.appService.POST(url, formData).subscribe((res: any) => {
        if (res.hasOwnProperty('createdAt')) {
          this.snackbar.open('Company created successfully', '', {
            duration: 3000, verticalPosition: 'top', panelClass: 'success-dialog'
          });
          localStorage.setItem('companyData', JSON.stringify(res));
          this.router.navigate(['/company-loan-subscription']);
        }
      },

        err => {
          if (err.status === 500) {
            this.snackbar.open(err.error, '', {
              duration: 3000, verticalPosition: 'top', panelClass: 'success-dialog'
            });
          }
        })
    }
  }
}

