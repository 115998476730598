import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Auth
import { LoginGuardService } from './core/guards';
import { AuthGuardService } from './core/guards';

// Common
import { ForgetPasswordComponent } from './common';

// Admin
import {
  SignupComponent, CompanyComponent, DashboardComponent,
  CompanyProfileAddTeamComponent, CompanyProfileBillingInfoComponent,
  LoanIntegrationSystemComponent, loanTypeComponent,
  LoginComponent, LoanSubscriptionComponent,
} from './admin-components';
import { SelectLoanTypeComponent } from './admin-components/select-loan-type/select-loan-type.component';
import { ResetPasswordComponent } from './common/reset-password/reset-password.component';


const routes: Routes = [
  { path: '', redirectTo: 'signup', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'loan-type', component: loanTypeComponent },
  { path: 'company-info', component: CompanyComponent },
  { path: 'company-add-team', component: CompanyProfileAddTeamComponent },
  { path: 'company-billing-info', component: CompanyProfileBillingInfoComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'forgot-password', component: ForgetPasswordComponent },
  { path: 'company-loan-subscription', component: LoanSubscriptionComponent },
  { path: 'company-los-integration', component: LoanIntegrationSystemComponent },
  { path: 'select-loan-type', component: SelectLoanTypeComponent },
  { path: 'reset-password/:code/:email', component: ResetPasswordComponent },
  { path: '**', redirectTo: 'error', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
