import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

// Api Service
import { AppService } from '../../services';

// Ngx
import { NgxSpinnerService } from 'ngx-spinner';

// Angular Material
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetForm: FormGroup;
  formSubmitted = false;
  responseData: any;
  code: any;
  email: any;

  constructor(
    private router: Router, private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
    private snackbar: MatSnackBar, private route: ActivatedRoute, private appService: AppService
  ) { }

  ngOnInit() {

    this.code = this.route.snapshot.paramMap.get('code');
    this.email = this.route.snapshot.paramMap.get('email');

    this.resetForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    });
  }

  // Convenience getter for easy access to form feild
  get f() { return this.resetForm.controls; }

  // Forgot Password Api call
  onSubmit() {
    console.log(this.resetForm.value)
    this.formSubmitted = true;

    if (this.resetForm.invalid) {
      return;
    } else {
      this.spinner.show();
      console.log(this.resetForm);
      if (this.resetForm.value.password === this.resetForm.value.confirmPassword) {
        this.spinner.hide();

        let url = 'reset-password';
        let data = {
          email: this.email,
          code: this.code,
          newpassword: this.resetForm.value.confirmPassword,
        }
        this.appService.POST(url, data).subscribe((res: any) => {
          this.snackbar.open('Password Reset successfully.', '', {
            duration: 3000, verticalPosition: 'top', panelClass: 'success-dialog'
          });
        },
          err => {
            if (err.status === 500) {
              this.snackbar.open(err.error, '', {
                duration: 3000, verticalPosition: 'top', panelClass: 'success-dialog'
              });
            }
          })

      } else {
        this.spinner.hide();
        this.snackbar.open('New Password and confirm password do not match.', '', {
          duration: 3000, verticalPosition: 'top', panelClass: 'success-dialog'
        });
      }
    }
  }
}

