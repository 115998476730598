import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from '../environments/environment.prod';

//Stripe
import { StripeModule } from "stripe-angular";

// Ngx
import { NgxSpinnerModule } from 'ngx-spinner';
import { CookieService } from 'ngx-cookie-service';


// Angular Material
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';


// Auth
import { AuthGuardService, LoginGuardService } from './core/guards/';


// Common
import {
  PageNotFoundComponent, FooterComponent, ForgetPasswordComponent,
  StatusProgressBarComponent, CompanyPageDotsComponent, ConfirmationComponent
} from './common';


// Admin Pages
import {
  SignupComponent, CompanyComponent, DashboardComponent,
  CompanyProfileAddTeamComponent, CompanyProfileBillingInfoComponent,
  LoanIntegrationSystemComponent, loanTypeComponent,
  NavComponent, LoginComponent, LoanSubscriptionComponent
} from './admin-components';
import { SelectLoanTypeComponent } from './admin-components/select-loan-type/select-loan-type.component';
import { ResetPasswordComponent } from './common/reset-password/reset-password.component';


@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    loanTypeComponent,
    NavComponent,
    FooterComponent,
    CompanyComponent,
    ConfirmationComponent,
    DashboardComponent,
    SignupComponent,
    CompanyProfileAddTeamComponent,
    CompanyProfileBillingInfoComponent,
    LoanIntegrationSystemComponent,
    CompanyPageDotsComponent,
    LoginComponent,
    ForgetPasswordComponent,
    StatusProgressBarComponent,
    LoanSubscriptionComponent,
    SelectLoanTypeComponent,
    ResetPasswordComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule, HttpClientModule, MatPaginatorModule,
    BrowserAnimationsModule, MatFormFieldModule, MatTableModule,
    MatInputModule, MatRadioModule, MatSortModule, MatTooltipModule,
    MatButtonModule, FormsModule, ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    MatListModule, NgxSpinnerModule, MatSnackBarModule,
    MatIconModule, MatDialogModule, MatDatepickerModule, MatNativeDateModule,
    MatAutocompleteModule, MatCardModule, MatCheckboxModule, MatSlideToggleModule,
    [StripeModule.forRoot(environment.publishable_key)]
  ],
  providers: [
    CookieService,
    CompanyComponent,
    CompanyProfileAddTeamComponent,
    AuthGuardService,
    LoginGuardService,
    CompanyProfileBillingInfoComponent,
    LoanSubscriptionComponent,
    LoanIntegrationSystemComponent
  ],
  entryComponents: [
    ConfirmationComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
