import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';

// Environment
import { environment } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})

export class AppService {

  constructor(private httpClient: HttpClient) { }

  // This Http request used by company info, company add team ,login and signup for api call
  POST(url: string, data: object) {
    let token = localStorage.getItem('LoggedInToken');
    return this.httpClient.post(
      environment.apiURL + url, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  DELETE(url: string) {
    let token = localStorage.getItem('LoggedInToken');
    return this.httpClient.delete(
      environment.apiURL + url, {
      observe: 'response',
      headers: {
        'Authorization': 'bearer ' + token,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });
  }
}
