import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// Api and data service
import { AppService, DataService } from '../../services';

// Angular Material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from '../../services/common.service';

// Ngx
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService, private appService: AppService, private CommonService: CommonService,
    private snackbar: MatSnackBar, public dialog: MatDialog, public dataService: DataService) { }

  addUserForm: FormGroup;
  submitted = false;
  loggedinData: any;
  startDate = new Date(1990, 0, 1);
  viewForm = false;
  editForm = false;
  roleCheck = false;
  roles: any = [];

  ngOnInit() {
    this.roles = this.CommonService.getRole();
    this.addUserForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern(/^[a-zA-Z ]+$/), Validators.maxLength(80)]],
      // mobile: ['', [Validators.pattern(/^\+[1-9]{1}[0-9]{10,15}$/)]], //+mandotory in phone number
      email: ['', [Validators.required, Validators.email]],
      Password: ['', [Validators.required, Validators.maxLength(30)]],
      confirmPassword: ['', [Validators.required, Validators.maxLength(30)]],
      role: ['', [Validators.required]]
    });
    this.loggedinData = JSON.parse(localStorage.getItem('loggedData'));
    this.addUserForm.get('role').setValue('LoanProcessor');
  }

  // Convenience getter for easy access to form feild
  get f() { return this.addUserForm.controls; }

  // For Post data to Api and receive response
  onAdd(data) {
    this.submitted = true;
    console.log(this.addUserForm.value);

    if (this.addUserForm.invalid && this.addUserForm.value.role.length === 0) {
      this.roleCheck = true;
      return;
    } else
      this.roleCheck = false;
    {
      if (this.addUserForm.value.Password === this.addUserForm.value.confirmPassword) {
        this.spinner.show();
        let formData = {
          email: this.addUserForm.value.email,
          password: this.addUserForm.value.Password,
          name: this.addUserForm.value.name,
          role: this.addUserForm.value.role
        }

        console.log(formData);
        let url = 'signup';
        this.appService.POST(url, formData).subscribe((res: any) => {
          this.spinner.hide();
          console.log(res);
          if (res.status === 200) {
            this.snackbar.open('Sign Up done successfully', '', {
              duration: 3000, verticalPosition: 'top', panelClass: 'success-dialog'
            });
            localStorage.setItem('SingedInData', JSON.stringify(res));
            this.router.navigate(['/company-info']);
          }

        }, err => {
          console.log(err);
          this.spinner.hide();
          if (err.status === 500) {
            this.snackbar.open(err.error, '', {
              duration: 3000, verticalPosition: 'top', panelClass: 'success-dialog'
            });
          } else {
            this.snackbar.open(err.message, '', {
              duration: 3000, verticalPosition: 'top', panelClass: 'success-dialog'
            });
          }
        })
      }
      else {
        this.snackbar.open('Passwords do not match', '', {
          duration: 3000, verticalPosition: 'top', panelClass: 'success-dialog'
        });
      }
    }
  }
}
