import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

// Rxjs
import { Observable } from 'rxjs';

// Auth Service
import { CheckLoginAuthService } from './check-login-auth.service';

// Ngx
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})


export class AuthGuardService {
  constructor(private checkLogin: CheckLoginAuthService,
              private router: Router, private cookieService: CookieService) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.checkLogin.isAuthenticated()) {
      return true;
    } else {
      console.log('wrong', this.cookieService.get('urlString') + '/login');
      this.router.navigate(['admin' + '/login']);
      return false;
    }
  }
}
