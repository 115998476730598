import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of } from 'rxjs';


import { AppService } from './app.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';


@Injectable({
  providedIn: 'root'
})
export class DataService {

  private messageSource = new BehaviorSubject('');

  currentMessage = this.messageSource.asObservable();

  constructor(private router: Router, private snackbar: MatSnackBar, private appService: AppService) { }

  options: any;
  loggedinData: any;


  loanTypeData = [
    {
        name: 'amort_type',
        label: 'Amort type',
        description: 'Fixed or ARM?',
        groupName: 'loaninfo',
        type: 'enumeration',
        fieldType: 'select',
        options: [
            {
                label: 'Fixed rate',
                displayOrder: -1,
                hidden: false,
                readOnly: false,
                doubleData: 0.0,
                description: null,
                value: 'fixed'
            },
            {
                label: 'ARM',
                displayOrder: 1,
                hidden: false,
                readOnly: false,
                doubleData: 0.0,
                description: null,
                value: 'arm'
            }
        ]
    },
    {
        name: 'appraisal_report',
        label: 'Appraisal received',
        description: 'Date for Appraisal received',
        groupName: 'closingplan',
        type: 'date',
        fieldType: 'date'
    },
    {
        name: 'appraised_value',
        label: 'Appraised value',
        description: '',
        groupName: 'loaninfo',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'approved',
        label: 'Approved',
        description: 'Date Approved',
        groupName: 'loanstatus',
        type: 'date',
        fieldType: 'date'
    },
    {
        name: 'apr',
        label: 'APR',
        description: '',
        groupName: 'loaninfo',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'aus',
        label: 'AUS',
        description: 'Automated underwriting recommendation',
        groupName: 'qualify',
        type: 'string',
        fieldType: 'text'
    },
    {
        name: 'borrower_info',
        label: 'Borrower info',
        description: '',
        groupName: 'loaninfo',
        type: 'string',
        fieldType: 'text'
    },
    {
        name: 'city',
        label: 'City',
        description: '',
        groupName: 'subjectproperty',
        type: 'string',
        fieldType: 'text'
    },
    {
        name: 'clear_to_close',
        label: 'Clear to close',
        description: '',
        groupName: 'loanstatus',
        type: 'date',
        fieldType: 'date'
    },
    {
        name: 'county',
        label: 'County',
        description: 'Subject property county',
        groupName: 'subjectproperty',
        type: 'string',
        fieldType: 'text'
    },
    {
        name: 'custom_event_1',
        label: 'Custom event 1 ',
        description: 'sU1LStatD',
        groupName: 'loanstatus',
        type: 'date',
        fieldType: 'date'
    },
    {
        name: 'custom_event_2',
        label: 'Custom event 2',
        description: 'sU2LStatD',
        groupName: 'loanstatus',
        type: 'date',
        fieldType: 'date'
    },
    {
        name: 'custom_event_3',
        label: 'Custom event 3',
        description: 'sU3LStatD',
        groupName: 'loanstatus',
        type: 'date',
        fieldType: 'date'
    },
    {
        name: 'custom_event_4',
        label: 'Custom event 4',
        description: 'sU4LStatD',
        groupName: 'loanstatus',
        type: 'date',
        fieldType: 'date'
    },
    {
        name: 'docs_due',
        label: 'Docs due',
        description: 'Last day to receive docs to meet closing date',
        groupName: 'closingplan',
        type: 'date',
        fieldType: 'date'
    },
    {
        name: 'down_payment',
        label: 'Down payment $',
        description: '',
        groupName: 'loaninfo',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'down_payment_percent',
        label: 'Down payment %',
        description: '',
        groupName: 'loaninfo',
        type: 'number',
        fieldType: 'number',
        deleted: false
    },
    {
        name: 'dti',
        label: 'DTI',
        description: 'Debt-to-income',
        groupName: 'qualify',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'estimated_closing',
        label: 'Estimated closing date',
        description: '',
        groupName: 'closingplan',
        type: 'date',
        fieldType: 'date'
    },
    {
        name: 'estimated_closing_costs',
        label: 'Closing costs',
        description: '',
        groupName: 'detailsoftransaction',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'estimated_prepaid_items',
        label: 'Estimated prepaid items',
        description: 'sTotEstPp1003',
        groupName: 'detailsoftransaction',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'fico',
        label: 'Credit Score',
        description: 'sCreditScoreType2',
        groupName: 'loaninfo',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'hazard_ins',
        label: 'Hazard ins',
        description: '',
        groupName: 'loaninfo',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'hoa',
        label: 'HOA',
        description: '',
        groupName: 'loaninfo',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'is_renovationloan',
        label: 'Renovation loan',
        description: '',
        groupName: 'renovationloan',
        type: 'enumeration',
        fieldType: 'booleancheckbox',
        options: [
            {
                label: 'No',
                displayOrder: -1,
                hidden: false,
                readOnly: false,
                doubleData: 0.0,
                description: null,
                value: 'false'
            },
            {
                label: 'Yes',
                displayOrder: -1,
                hidden: false,
                readOnly: false,
                doubleData: 0.0,
                description: null,
                value: 'true'
            }
        ]
    },
    {
        name: 'loan_amount_base',
        label: 'Loan amount',
        description: '',
        groupName: 'loaninfo',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'loan_number',
        label: 'Loan number',
        description: '',
        groupName: 'loaninfo',
        type: 'string',
        fieldType: 'text',
        hasUniqueValue: true,
        searchableInGlobalSearch: true
    },
    {
        name: 'loan_purpose',
        label: 'Loan purpose',
        description: '',
        groupName: 'loaninfo',
        type: 'enumeration',
        fieldType: 'select',
        options: [
            {
                label: 'Purchase',
                displayOrder: 1,
                hidden: false,
                readOnly: false,
                doubleData: 0.0,
                description: null,
                value: 'Purchase'
            },
            {
                label: 'Refi rate/term',
                displayOrder: 2,
                hidden: false,
                readOnly: false,
                doubleData: 0.0,
                description: null,
                value: 'Refi rate/term'
            },
            {
                label: 'Refinance cashout',
                displayOrder: 3,
                hidden: false,
                readOnly: false,
                doubleData: 0.0,
                description: null,
                value: 'Refinance cashout'
            },
            {
                label: 'Construction',
                displayOrder: 4,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Construction'
            },
            {
                label: 'Construction perm',
                displayOrder: 5,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Construction perm'
            },
            {
                label: 'FHA streamline refinance',
                displayOrder: 6,
                hidden: false,
                readOnly: false,
                doubleData: 0.0,
                description: null,
                value: 'FHA streamline refinance'
            },
            {
                label: 'VA IRRRL',
                displayOrder: 7,
                hidden: false,
                readOnly: false,
                doubleData: 0.0,
                description: null,
                value: 'VA IRRRL'
            },
            {
                label: 'Other',
                displayOrder: 8,
                hidden: false,
                readOnly: false,
                doubleData: 0.0,
                description: null,
                value: 'Other'
            }
        ]
    },
    {
        name: 'loan_type',
        label: 'Loan Type',
        description: '',
        groupName: 'loaninfo',
        type: 'enumeration',
        fieldType: 'select',
        options: [
            {
                label: 'Conventional',
                displayOrder: -1,
                hidden: false,
                readOnly: false,
                doubleData: 0.0,
                description: null,
                value: 'Conventional'
            },
            {
                label: 'FHA',
                displayOrder: 1,
                hidden: false,
                readOnly: false,
                doubleData: 0.0,
                description: null,
                value: 'FHA'
            },
            {
                label: 'VA',
                displayOrder: 2,
                hidden: false,
                readOnly: false,
                doubleData: 0.0,
                description: null,
                value: 'VA'
            },
            {
                label: 'USDA/rural housing',
                displayOrder: 3,
                hidden: false,
                readOnly: false,
                doubleData: 0.0,
                description: null,
                value: 'USDA/rural housing'
            },
            {
                label: 'Other',
                displayOrder: 4,
                hidden: false,
                readOnly: false,
                doubleData: 0.0,
                description: null,
                value: 'Other'
            }
        ]
    },
    {
        name: 'ltv',
        label: 'Loan-to-value',
        description: 'Loan-to-value',
        groupName: 'qualify',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'mortgage_commitment',
        label: 'Mortgage commitment due',
        description: 'Financing contingency expiration date',
        groupName: 'closingplan',
        type: 'date',
        fieldType: 'date'
    },
    {
        name: 'mortgage_insurance',
        label: 'Mortgage insurance',
        description: '',
        groupName: 'loaninfo',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'no_units',
        label: 'Units',
        description: '',
        groupName: 'subjectproperty',
        type: 'enumeration',
        fieldType: 'select',
        options: [
            {
                label: '1',
                displayOrder: -1,
                hidden: false,
                readOnly: false,
                doubleData: 0.0,
                description: null,
                value: '1'
            },
            {
                label: '2',
                displayOrder: 1,
                hidden: false,
                readOnly: false,
                doubleData: 0.0,
                description: null,
                value: '2'
            },
            {
                label: '3',
                displayOrder: 2,
                hidden: false,
                readOnly: false,
                doubleData: 0.0,
                description: null,
                value: '3'
            },
            {
                label: '4',
                displayOrder: 3,
                hidden: false,
                readOnly: false,
                doubleData: 0.0,
                description: null,
                value: '4'
            }
        ]
    },
    {
        name: 'note_rate',
        label: 'Note rate',
        description: '',
        groupName: 'loaninfo',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'other_financing',
        label: 'Other financing',
        description: '',
        groupName: 'loaninfo',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'other_taxes_and_expenses',
        label: 'Other taxes and expenses',
        description: '',
        groupName: 'loaninfo',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'p_i',
        label: 'P&I',
        description: '',
        groupName: 'loaninfo',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'piti',
        label: 'PITI',
        description: '',
        groupName: 'loaninfo',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'pres_house_exp',
        label: 'Pres House Exp',
        description: '',
        groupName: 'loaninfo',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'present_housing_expense',
        label: 'Present housing expense',
        description: '',
        groupName: 'qualify',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'property_taxes',
        label: 'Property taxes',
        description: '',
        groupName: 'loaninfo',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'property_type',
        label: 'Property type',
        description: '',
        groupName: 'subject_property_description',
        type: 'enumeration',
        fieldType: 'select',
        options: [
            {
                label: 'SFR',
                displayOrder: 3,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'SFR'
            },
            {
                label: '2 Unit',
                displayOrder: 4,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Two Units'
            },
            {
                label: '3 Unit',
                displayOrder: 5,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Three Units'
            },
            {
                label: '4 Unit',
                displayOrder: 6,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Four Units'
            },
            {
                label: 'PUD',
                displayOrder: 7,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'PUD'
            },
            {
                label: 'Condo',
                displayOrder: 8,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Condo'
            },
            {
                label: 'Co-op',
                displayOrder: 9,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'CoOp'
            },
            {
                label: 'Manufactured',
                displayOrder: 10,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Manufactured'
            },
            {
                label: 'Rowhouse',
                displayOrder: 11,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Rowhouse'
            },
            {
                label: 'Modular',
                displayOrder: 12,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Modular'
            }
        ]
    },
    {
        name: 'purchase_contract_date',
        label: 'Purchase contract date ',
        description: '',
        groupName: 'closingplan',
        type: 'date',
        fieldType: 'date'
    },
    {
        name: 'purchase_price',
        label: 'Purchase price',
        description: '',
        groupName: 'loaninfo',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'rate_lock_expiration',
        label: 'Rate lock expiration',
        description: '',
        groupName: 'closingplan',
        type: 'date',
        fieldType: 'date'
    },
    {
        name: 'seller_credit',
        label: 'Seller credit',
        description: ' sOCredit3Amt',
        groupName: 'detailsoftransaction',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'state',
        label: 'State',
        description: '',
        groupName: 'subjectproperty',
        type: 'enumeration',
        fieldType: 'select',
        options: [
            {
                label: 'Alabama',
                displayOrder: 1,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Alabama'
            },
            {
                label: 'Alaska',
                displayOrder: 2,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Alaska'
            },
            {
                label: 'Arizona',
                displayOrder: 3,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Arizona'
            },
            {
                label: 'Arkansas',
                displayOrder: 4,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Arkansas'
            },
            {
                label: 'California',
                displayOrder: 5,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'California'
            },
            {
                label: 'Colorado',
                displayOrder: 6,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Colorado'
            },
            {
                label: 'Connecticut',
                displayOrder: 7,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Connecticut'
            },
            {
                label: 'Delaware',
                displayOrder: 8,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Delaware'
            },
            {
                label: 'Florida',
                displayOrder: 9,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Florida'
            },
            {
                label: 'Georgia',
                displayOrder: 10,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Georgia'
            },
            {
                label: 'Hawaii',
                displayOrder: 11,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Hawaii'
            },
            {
                label: 'Idaho',
                displayOrder: 12,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Idaho'
            },
            {
                label: 'Illinois',
                displayOrder: 13,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Illinois'
            },
            {
                label: 'Indiana',
                displayOrder: 14,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Indiana'
            },
            {
                label: 'Iowa',
                displayOrder: 15,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Iowa'
            },
            {
                label: 'Kansas',
                displayOrder: 16,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Kansas'
            },
            {
                label: 'Kentucky',
                displayOrder: 17,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Kentucky'
            },
            {
                label: 'Louisiana',
                displayOrder: 18,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Louisiana'
            },
            {
                label: 'Maine',
                displayOrder: 19,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Maine'
            },
            {
                label: 'Maryland',
                displayOrder: 20,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Maryland'
            },
            {
                label: 'Massachusetts',
                displayOrder: 21,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Massachusetts'
            },
            {
                label: 'Michigan',
                displayOrder: 22,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Michigan'
            },
            {
                label: 'Minnesota',
                displayOrder: 23,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Minnesota'
            },
            {
                label: 'Mississippi',
                displayOrder: 24,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Mississippi'
            },
            {
                label: 'Missouri',
                displayOrder: 25,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Missouri'
            },
            {
                label: 'Montana',
                displayOrder: 26,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Montana'
            },
            {
                label: 'Nebraska',
                displayOrder: 27,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Nebraska'
            },
            {
                label: 'Nevada',
                displayOrder: 28,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Nevada'
            },
            {
                label: 'New Hampshire',
                displayOrder: 29,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'New Hampshire'
            },
            {
                label: 'New Jersey',
                displayOrder: 30,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'New Jersey'
            },
            {
                label: 'New Mexico',
                displayOrder: 31,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'New Mexico'
            },
            {
                label: 'New York',
                displayOrder: 32,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'New York'
            },
            {
                label: 'North Carolina',
                displayOrder: 33,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'North Carolina'
            },
            {
                label: 'North Dakota',
                displayOrder: 34,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'North Dakota'
            },
            {
                label: 'Ohio',
                displayOrder: 35,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Ohio'
            },
            {
                label: 'Oklahoma',
                displayOrder: 36,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Oklahoma'
            },
            {
                label: 'Oregon',
                displayOrder: 37,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Oregon'
            },
            {
                label: 'Pennsylvania',
                displayOrder: 38,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Pennsylvania'
            },
            {
                label: 'Rhode Island',
                displayOrder: 39,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Rhode Island'
            },
            {
                label: 'South Carolina',
                displayOrder: 40,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'South Carolina'
            },
            {
                label: 'South Dakota',
                displayOrder: 41,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'South Dakota'
            },
            {
                label: 'Tennessee',
                displayOrder: 42,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Tennessee'
            },
            {
                label: 'Texas',
                displayOrder: 43,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Texas'
            },
            {
                label: 'Utah',
                displayOrder: 44,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Utah'
            },
            {
                label: 'Vermont',
                displayOrder: 45,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Vermont'
            },
            {
                label: 'Virginia',
                displayOrder: 46,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Virginia'
            },
            {
                label: 'Washington',
                displayOrder: 47,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Washington'
            },
            {
                label: 'West Virginia',
                displayOrder: 48,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'West Virginia'
            },
            {
                label: 'Wisconsin',
                displayOrder: 49,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Wisconsin'
            },
            {
                label: 'Wyoming',
                displayOrder: 50,
                hidden: false,
                readOnly: false,
                doubleData: null,
                description: null,
                value: 'Wyoming'
            }
        ]
    },
    {
        name: 'street',
        label: 'Street',
        description: '',
        groupName: 'subjectproperty',
        type: 'string',
        fieldType: 'text'
    },
    {
        name: 'subj_prop_occ',
        label: 'Subj prop occ',
        description: '',
        groupName: 'loaninfo',
        type: 'enumeration',
        fieldType: 'select',
        options: [
            {
                label: 'Primary residence',
                displayOrder: -1,
                hidden: false,
                readOnly: false,
                doubleData: 0.0,
                description: null,
                value: 'Primary residence'
            },
            {
                label: 'Secondary residence',
                displayOrder: 1,
                hidden: false,
                readOnly: false,
                doubleData: 0.0,
                description: null,
                value: 'Secondary residence'
            },
            {
                label: 'Investment',
                displayOrder: 2,
                hidden: false,
                readOnly: false,
                doubleData: 0.0,
                description: null,
                value: 'Investment'
            }
        ]
    },
    {
        name: 'term_mths',
        label: 'Term mths',
        description: '',
        groupName: 'loaninfo',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'zip',
        label: 'Zip',
        description: '',
        groupName: 'subjectproperty',
        type: 'string',
        fieldType: 'text'
    },
    {
        name: 'as_completed_value',
        label: 'As-completed value',
        description: '',
        groupName: 'renovationloan',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'pin',
        label: 'PIN',
        description: '',
        groupName: 'subjectproperty',
        type: 'string',
        fieldType: 'text'
    },
    {
        name: 'refi_incl_debts_to_be_paid_off_',
        label: 'Refi (incl debts to be paid off)',
        description: '',
        groupName: 'detailsoftransaction',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'agency_case_number',
        label: 'Agency case number',
        description: '',
        groupName: 'loaninfo',
        type: 'string',
        fieldType: 'text'
    },
    {
        name: 'as_is_value',
        label: 'As-is value',
        description: '',
        groupName: 'renovationloan',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'credit_received',
        label: 'Credit received',
        description: '',
        groupName: 'closingplan',
        type: 'date',
        fieldType: 'date'
    },
    {
        name: 'total_debt',
        label: 'Total debt',
        description: 'Total all monthly payments',
        groupName: 'qualify',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'arm_1st_change',
        label: ' ARM 1st Change',
        description: 'sRAdj1stCapMon',
        groupName: 'loaninfo',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'income_total',
        label: 'Income total',
        description: 'Total monthly income',
        groupName: 'qualify',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'total_renovation_costs',
        label: 'Total renovation costs',
        description: '',
        groupName: 'renovationloan',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'arm_life_adj_cap',
        label: ' ARM Life Adj Cap',
        description: 'sRAdjCapMon',
        groupName: 'loaninfo',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'cost_of_repairs',
        label: 'Cost of repairs',
        description: '',
        groupName: 'renovationloan',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'pmi_mip_funding_fee',
        label: 'PMI, MIP, Funding Fee',
        description: '',
        groupName: 'detailsoftransaction',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'contingency_reserve',
        label: 'Contingency reserve',
        description: '',
        groupName: 'renovationloan',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'discount_if_borrower_will_pay_',
        label: 'Discount (if borrower will pay)',
        description: '',
        groupName: 'detailsoftransaction',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'other_debt',
        label: 'Other debt',
        description: 'Total non-mortgage monthly debt',
        groupName: 'qualify',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'inspection_fees',
        label: 'Inspection fees',
        description: '',
        groupName: 'renovationloan',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'subordinate_financing',
        label: 'Subordinate financing',
        description: '',
        groupName: 'detailsoftransaction',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'architect_engineer_fee',
        label: 'Architect engineer fee',
        description: '',
        groupName: 'renovationloan',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'consultant_fee',
        label: 'Consultant fee',
        description: '',
        groupName: 'renovationloan',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'lender_credit',
        label: 'Lender credit',
        description: '',
        groupName: 'detailsoftransaction',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'cash_from_to_borrower',
        label: 'Cash from / to borrower ',
        description: '',
        groupName: 'detailsoftransaction',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'permits_fee',
        label: 'Permits fee',
        description: '',
        groupName: 'renovationloan',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'plan_reviewer_fee',
        label: 'Plan reviewer fee',
        description: '',
        groupName: 'renovationloan',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'supplemental_origination_fee',
        label: 'Supplemental origination fee',
        description: '',
        groupName: 'renovationloan',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'tolerance_cures',
        label: 'Tolerance cures',
        description: 'sToleranceCure_Neg',
        groupName: 'detailsoftransaction',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'seller_credits',
        label: 'Seller credits',
        description: 'sTRIDLoanEstimateSellerCredits',
        groupName: 'detailsoftransaction',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'down_payment_fund_from_borrower',
        label: 'Down payment fund from borrower',
        description: 'sTRIDLoanEstimateDownPaymentOrFundsFromBorrower',
        groupName: 'detailsoftransaction',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'cash_deposit',
        label: 'Cash deposit',
        description: 'sTRIDCashDeposit',
        groupName: 'detailsoftransaction',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'additional_credit_at_closing',
        label: 'Additional credit at closing',
        description: 'sLenderAdditionalCreditAtClosingAmt_Neg',
        groupName: 'detailsoftransaction',
        type: 'number',
        fieldType: 'number'
    },
    {
        name: 'appraisal_ordered',
        label: 'Appraisal ordered',
        description: '',
        groupName: 'closingplan',
        type: 'date',
        fieldType: 'date'
    },
    {
        name: 'first_payment_due',
        label: 'First payment due',
        description: '',
        groupName: 'closingplan',
        type: 'date',
        fieldType: 'date'
    },
    {
        name: 'closing_disclosure_deadline',
        label: 'Closing Disclosure deadline',
        description: 'Last day for borrower to receive the CD',
        groupName: 'closingplan',
        type: 'date',
        fieldType: 'date'
    },
    {
        name: 'initial_le_issued',
        label: 'Initial LE issued',
        description: 'sInitialLoanEstimateCreatedD',
        groupName: 'closingplan',
        type: 'date',
        fieldType: 'date'
    },
    {
        name: 'initial_cd_issued',
        label: 'Initial CD issued',
        description: 'sInitialClosingDisclosureCreatedD',
        groupName: 'closingplan',
        type: 'date',
        fieldType: 'date'
    }
];


  changeMessage(message: string) {
    this.messageSource.next(message);
  }



  getLoanTypeData() {
    return of(this.loanTypeData);
  }


  // autoComplete searching


  errorMsg(errorObj) {

    if (errorObj.error.status != null) {

      switch (errorObj.error.status) {
        case 500: return "Internal_Server_Error"
          break;
        case 404: return "Unable_to_proceed_data_not_available"
          break;
        case 409: return "Already_exists"
          break;
        default:
          return "Data_Insufficient"
          break;
      }
    } else {
      this.logout();
    }
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['admin/login']);

  //   this.loggedinData = JSON.parse(localStorage.getItem("loggedData"));
  //   // localStorage.clear();
  //   // setTimeout(() => {
  //   //   this.router.navigate([this.loggedinData.routerName + '/login']);
  //   // }, 1500)

  //   let url = 'oauth/revokeToken'
  //   this.appService.DELETE(url).subscribe((res: any) => {
  //     console.log(this.loggedinData);
  //     localStorage.clear();
  //     this.router.navigate([this.loggedinData.routerName + '/login']);
  //   }, err => {
  //   localStorage.clear();
  //   setTimeout(() => {
  //     this.router.navigate([this.loggedinData.routerName + '/login']);
  //   }, 1500)
  //     this.snackbar.open(this.translatePipe.transform("Your_account_is_logged_in_from_another_browser,_Please_login_in_again"), '', {
  //       duration: 3000, verticalPosition: 'top', panelClass: "success-dialog"
  //     });
  //     })

  }


}
