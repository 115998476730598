import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

// Api Service
import { AppService } from '../../services';

// Ngx
import { NgxSpinnerService } from 'ngx-spinner';

// Angular Material
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})

export class ForgetPasswordComponent implements OnInit {

  forgotForm: FormGroup;
  formSubmitted = false;
  responseData: any;

  constructor(
    private router: Router, private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
    private snackbar: MatSnackBar, private activatedRoute: ActivatedRoute, private appService: AppService
  ) { }

  ngOnInit() {
    this.forgotForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  // Convenience getter for easy access to form feild
  get f() { return this.forgotForm.controls; }

  // Forgot Password Api call
  onSubmit() {
    console.log(this.forgotForm.value)
    this.formSubmitted = true;

    if (this.forgotForm.invalid) {
      this.snackbar.open('Sorry Email Format is Wrong ', '', {
        duration: 3000, verticalPosition: 'top', panelClass: 'success-dialog'
      });
      return;
    } else {

      let url = 'forgot-password';
      let data = {
        email: this.forgotForm.value.email,
      }
      this.appService.POST(url, data).subscribe((res: any) => {
        this.snackbar.open('Reset password link send successfully. please check mail.', '', {
          duration: 3000, verticalPosition: 'top', panelClass: 'success-dialog'
        });
      },
        err => {
          if (err.status === 500) {
            this.snackbar.open(err.error, '', {
              duration: 3000, verticalPosition: 'top', panelClass: 'success-dialog'
            });
          }
        })
    }
  }
}
