import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

//Angular Material
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

//rxjs
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

//Common
import { ConfirmationComponent } from '../../common/confirmation/confirmation.component';

//Ngx
import { NgxSpinnerService } from "ngx-spinner";

//data and auth service
import { AppService, DataService } from '../../services';
import { CheckLoginAuthService } from '../../core/guards/check-login-auth.service';


export interface TableData { }

export interface DialogData { }

const TableData: TableData[] = []
let Check: boolean = true;

@Component({
  selector: 'app-loanType',
  templateUrl: './loanType.component.html',
  styleUrls: ['./loanType.component.scss']
})

export class loanTypeComponent implements OnInit {

  selectedItem1: boolean = false;
  selectedItem2: boolean = false;
  selectedItem3: boolean = false;
  dataSource = new MatTableDataSource<TableData>(TableData);
  showNav: boolean = false;

  loanTypes: any = [
    {
      "name": "loaninfo",
      "displayName": "LoanInfo",
      "displayOrder": 2
    },
    {
      "name": "detailsoftransaction",
      "displayName": "Details of Transaction",
      "displayOrder": 3
    },
    {
      "name": "subjectproperty",
      "displayName": "Subject Property",
      "displayOrder": 4
    },
    {
      "name": "closingplan",
      "displayName": "Closing Plan",
      "displayOrder": 5
    },
    {
      "name": "loanstatus",
      "displayName": "Loan Status",
      "displayOrder": 6
    },
    {
      "name": "renovationloan",
      "displayName": "Renovation Loan",
      "displayOrder": 7
    },
    {
      "name": "qualify",
      "displayName": "Qualify",
      "displayOrder": 8
    }
  ]

  getLoanTypeData: any;
  LoanTypeProperties: any;

  paginationData: any = [];
  pageNumber: number = 0;

  constructor(private checkLogin: CheckLoginAuthService, private AppService: AppService,
    private spinner: NgxSpinnerService, public dialog: MatDialog, private router: Router,
    private snackbar: MatSnackBar, private dataService: DataService) { }

  ngOnInit() {

    this.dataService.getLoanTypeData().subscribe((res: any) => {
      console.log(res);
      this.getLoanTypeData = res;
    }, err => { console.log(err) });

    this.selectLoanType({ name: "loaninfo" });

  }


  showNavFnc() {
    //console.log('called');
    if (this.showNav) {
      this.showNav = false;
    } else {
      this.showNav = true;
    }
  }


  selectFnc(data: any) {
    if (data.hasOwnProperty('selected')) {
      if (data['selected']) {
        data['selected'] = false;
      } else {
        data['selected'] = true;
      }
    } else {
      data['selected'] = true;
    }
    // return data; 
  }


  selectLoanType(data) {
    console.log(data);
    this.LoanTypeProperties = this.getLoanTypeData.filter((item) => {
      if (item.groupName == data.name) {
        return item;
      }
    })

    this.nextData(this.pageNumber);
    // this.pageNumber = 1;
    console.log(this.LoanTypeProperties);
    var lenOfData = this.LoanTypeProperties.length;
    var nowNo;
    // for(var dr=0; dr<5;dr++){

    //   if(nowNo==null){
    //     nowNo = 0;
    //   }else if(nowNo == 0){
    //     nowNo = nowNo+4;
    //   }
    //   if(lenOfData-(4*dr)){
    //     for(var y=nowNo;y<5;y++){
    //       this.dataRow.push(this.LoanTypeProperties[y]);
    //     }
    //   }
    // }
    for (var y = 0; y < 5; y++) {
      this.dataRow1.push(this.LoanTypeProperties[y]);
    }
    for (var y = 4; y < 8; y++) {
      this.dataRow2.push(this.LoanTypeProperties[y]);
    }
    for (var y = 8; y < 12; y++) {
      this.dataRow3.push(this.LoanTypeProperties[y]);
    }
    for (var y = 12; y < 16; y++) {
      this.dataRow4.push(this.LoanTypeProperties[y]);
    }
    for (var y = 16; y < 20; y++) {
      this.dataRow5.push(this.LoanTypeProperties[y]);
    }
    for (var y = 20; y < 24; y++) {
      this.dataRow6.push(this.LoanTypeProperties[y]);
    }
  }

  dataRow1: any = [];
  dataRow2: any = [];
  dataRow3: any = [];
  dataRow4: any = [];
  dataRow5: any = [];
  dataRow6: any = [];

  previousData(pno) {
    console.log(pno);
    if (pno > 1) {
      let counter = 0;
      if (counter == 0) {
        this.pageNumber = this.pageNumber - 1;
      }
      console.log(pno);
      if (this.pageNumber > 1) {
        this.pageNumber = this.pageNumber - 1;
      }
      this.paginationData.length = 0;
      for (let x = 4; x > 0; x--) {
        let y = (this.pageNumber * 4) - x;
        this.paginationData.push(this.LoanTypeProperties[y])
      }
      counter++;
    }
    console.log(this.pageNumber);

  }

  nextData(pno) {
    console.log(pno);
    console.log(this.LoanTypeProperties.length / 4);
    if (pno <= (this.LoanTypeProperties.length / 4) + 1) {
      console.log(pno);
      // if(pno<this.pageNumber||pno==0){
      // let upno = pno-1;  
      this.paginationData.length = 0;
      for (let x = 0; x < 4; x++) {
        let y = (pno * 4) + x;

        this.paginationData.push(this.LoanTypeProperties[y])
      }
      this.pageNumber = this.pageNumber + 1;
      // }    
    }
    console.log(this.pageNumber);
  }
}


