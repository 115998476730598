import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class CheckLoginAuthService {

  constructor(private router: Router) { }

  setToken(token: string) {
    localStorage.setitem('LoggedInToken', token);
  }

  getToken() {
    return localStorage.getItem('LoggedInToken');
  }

  isAuthenticated() {
    return this.getToken() !== null;
  }

  logout() {
    localStorage.remove('LoggedInToken');
    this.router.navigate(['']);
  }
}

