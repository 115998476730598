import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

// Api Service
import { AppService } from '../../services';

// Angular Material
import { MatSnackBar } from '@angular/material/snack-bar';

// Admin Components
import {
  CompanyComponent, CompanyProfileAddTeamComponent, CompanyProfileBillingInfoComponent,
  LoanSubscriptionComponent, LoanIntegrationSystemComponent
} from '../../admin-components';

@Component({
  selector: 'app-company-page-dots',
  templateUrl: './company-page-dots.component.html',
  styleUrls: ['./company-page-dots.component.scss']
})

export class CompanyPageDotsComponent implements OnInit {
  viewForm = false;
  locationVar: any = location.href;
  SingedInData: any = JSON.parse(localStorage.getItem('SingedInData'));
  CompanyInData: any = JSON.parse(localStorage.getItem('company'));
  dotPosition = 0;

  constructor(private router: Router, private appService: AppService, private snackbar: MatSnackBar,
    private companyComponent: CompanyComponent,
    private loanSubscriptionComponent: LoanSubscriptionComponent
  ) { }

  @Input('companyInfo') companyInfo: any;
  ngOnInit() {
    if (this.SingedInData == null) {
      this.SingedInData = {
        data: {
          createdAt: '2019-11-10T08:42:55.364Z',
          email: 'attheratevisasdshal@yopmail.com',
          role: 'LoanOfficer',
          updatedAt: '2019-11-10T08:42:55.364Z',
          __v: 0,
          _id: '5dc7cd8fe79ef40017917635',
        },
        message: 'User Signed Up Successfully',
        status: 200
      }
    }
  }

  // Dot navigation
  naviPage(dotPosition) {
    if (this.locationVar.includes('company-info')) {
      this.dotPosition = dotPosition;
      if (this.dotPosition === 2) {
        this.companyComponent.onAdd();
      }
    }
    if (this.locationVar.includes('company-loan-subscription')) {
      this.dotPosition = dotPosition;
      if (this.dotPosition === 1) {
        this.router.navigate(['/company-info']);
      }
      if (this.dotPosition === 3) {
        this.loanSubscriptionComponent.onAdd();
      }
    }
    if (this.locationVar.includes('company-billing-info')) {
      this.dotPosition = dotPosition;
      if (this.dotPosition === 2) {
        this.router.navigate(['/company-loan-subscription']);
      }
    }
  }
}

