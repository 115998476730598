import { State, Role } from './state.interface';


export const STATES: State[] = [
    {
        "value": "Alabama",
        "name": "AL",
        active: "true"
    },
    {
        "value": "Alaska",
        "name": "AK"
        , active: "true"
    },
    {
        "value": "American Samoa",
        "name": "AS"
        , active: "true"
    },
    {
        "value": "Arizona",
        "name": "AZ"
        , active: "true"
    },
    {
        "value": "Arkansas",
        "name": "AR"
        , active: "true"
    },
    {
        "value": "California",
        "name": "CA"
        , active: "true"
    },
    {
        "value": "Colorado",
        "name": "CO"
        , active: "true"
    },
    {
        "value": "Connecticut",
        "name": "CT"
        , active: "true"
    },
    {
        "value": "Delaware",
        "name": "DE"
        , active: "true"
    },
    {
        "value": "District Of Columbia",
        "name": "DC"
        , active: "true"
    },
    {
        "value": "Federated States Of Micronesia",
        "name": "FM"
        , active: "true"
    },
    {
        "value": "Florida",
        "name": "FL"
        , active: "true"
    },
    {
        "value": "Georgia",
        "name": "GA"
        , active: "true"
    },
    {
        "value": "Guam",
        "name": "GU"
        , active: "true"
    },
    {
        "value": "Hawaii",
        "name": "HI"
        , active: "true"
    },
    {
        "value": "Idaho",
        "name": "ID"
        , active: "true"
    },
    {
        "value": "Illinois",
        "name": "IL"
        , active: "true"
    },
    {
        "value": "Indiana",
        "name": "IN"
        , active: "true"
    },
    {
        "value": "Iowa",
        "name": "IA"
        , active: "true"
    },
    {
        "value": "Kansas",
        "name": "KS"
        , active: "true"
    },
    {
        "value": "Kentucky",
        "name": "KY"
        , active: "true"
    },
    {
        "value": "Louisiana",
        "name": "LA"
        , active: "true"
    },
    {
        "value": "Maine",
        "name": "ME"
        , active: "true"
    },
    {
        "value": "Marshall Islands",
        "name": "MH"
        , active: "true"
    },
    {
        "value": "Maryland",
        "name": "MD"
        , active: "true"
    },
    {
        "value": "Massachusetts",
        "name": "MA"
        , active: "true"
    },
    {
        "value": "Michigan",
        "name": "MI"
        , active: "true"
    },
    {
        "value": "Minnesota",
        "name": "MN"
        , active: "true"
    },
    {
        "value": "Mississippi",
        "name": "MS"
        , active: "true"
    },
    {
        "value": "Missouri",
        "name": "MO"
        , active: "true"
    },
    {
        "value": "Montana",
        "name": "MT"
        , active: "true"
    },
    {
        "value": "Nebraska",
        "name": "NE"
        , active: "true"
    },
    {
        "value": "Nevada",
        "name": "NV"
        , active: "true"
    },
    {
        "value": "New Hampshire",
        "name": "NH"
        , active: "true"
    },
    {
        "value": "New Jersey",
        "name": "NJ"
        , active: "true"
    },
    {
        "value": "New Mexico",
        "name": "NM"
        , active: "true"
    },
    {
        "value": "New York",
        "name": "NY"
        , active: "true"
    },
    {
        "value": "North Carolina",
        "name": "NC"
        , active: "true"
    },
    {
        "value": "North Dakota",
        "name": "ND"
        , active: "true"
    },
    {
        "value": "Northern Mariana Islands",
        "name": "MP"
        , active: "true"
    },
    {
        "value": "Ohio",
        "name": "OH"
        , active: "true"
    },
    {
        "value": "Oklahoma",
        "name": "OK"
        , active: "true"
    },
    {
        "value": "Oregon",
        "name": "OR"
        , active: "true"
    },
    {
        "value": "Palau",
        "name": "PW"
        , active: "true"
    },
    {
        "value": "Pennsylvania",
        "name": "PA"
        , active: "true"
    },
    {
        "value": "Puerto Rico",
        "name": "PR"
        , active: "true"
    },
    {
        "value": "Rhode Island",
        "name": "RI"
        , active: "true"
    },
    {
        "value": "South Carolina",
        "name": "SC"
        , active: "true"
    },
    {
        "value": "South Dakota",
        "name": "SD"
        , active: "true"
    },
    {
        "value": "Tennessee",
        "name": "TN"
        , active: "true"
    },
    {
        "value": "Texas",
        "name": "TX"
        , active: "true"
    },
    {
        "value": "Utah",
        "name": "UT"
        , active: "true"
    },
    {
        "value": "Vermont",
        "name": "VT"
        , active: "true"
    },
    {
        "value": "Virgin Islands",
        "name": "VI"
        , active: "true"
    },
    {
        "value": "Virginia",
        "name": "VA"
        , active: "true"
    },
    {
        "value": "Washington",
        "name": "WA"
        , active: "true"
    },
    {
        "value": "West Virginia",
        "name": "WV"
        , active: "true"
    },
    {
        "value": "Wisconsin",
        "name": "WI"
        , active: "true"
    },
    {
        "value": "Wyoming",
        "name": "WY"
        , active: "true"
    }

];

export const ROLES: Role[] = [
    { value: 'select', name: 'Select' },
    { value: 'operationsmanager', name: 'Operations Manager' },
    { value: 'loanprocessor', name: 'Loan Processor' },
    { value: 'underwriter', name: 'Underwriter' },
    { value: 'cto', name: 'CTO' },
    { value: 'closer', name: 'Closer' },
    { value: 'manager', name: 'Manager' }

];