import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-status-progress-bar',
  templateUrl: './status-progress-bar.component.html',
  styleUrls: ['./status-progress-bar.component.scss']
})

export class StatusProgressBarComponent implements OnInit {

  constructor(private router: Router) { }

  dataSelected: any = { isCurrent: [0, 0, 0, 0], isComplete: [0, 0, 0, 0] };
  currentPage: any;
  companyPage = false;
  signedInData: any = JSON.parse(localStorage.getItem('SingedInData'));

  @Input('companyInfo') companyInfo: any;

  ngOnInit() {
    this.currentPage = (location.href).split('/')[3];
    if (location.href.includes('company-info') || location.href.includes('company-billing-info')
      || location.href.includes('company-loan-subscription')) {
      this.companyPage = true;
    }
    if (location.href.includes('company'))
      this.currentPage = 'company';
  }

  selectPageFnc(name) {

    let currentURl = location.href;
    if (currentURl.includes('signup')) {
      this.currentPage = 'signup';
    }
    if (currentURl.includes('company')) {
      this.currentPage = 'company';
    }
    if (currentURl.includes('loanType')) {
      this.currentPage = 'loanType';
    }
    if (currentURl.includes('loan')) {
      this.currentPage = 'company';
    }

    if (name === 'signup') {
      this.router.navigate(['/signup']);
    } else if (name === 'company-info' && (this.signedInData != null)) {
      this.router.navigate(['/company-info']);
    } else if (name === 'loanType') {
      this.router.navigate(['/loanType']);
    } else if (name === 'review') {
    }
  }
}
