import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-loan-subscription',
  templateUrl: './loan-subscription.component.html',
  styleUrls: ['./loan-subscription.component.scss']
})
export class LoanSubscriptionComponent implements OnInit {
  plan1Price: any = 750;
  plan2Price: any = 2000;
  plan1Offer: any = 'Save 20% with annual subscription';
  plan2Offer: any = 'Save 20% with annual subscription';
  planPeriod: any = 'Monthly';

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  // Used For post data to api and check form is invalid or not. called when page dot click event invoked
  onAdd() {
    this.router.navigate(['/company-billing-info']);
  }

  periodChanger(period) {

    if (period === 'monthly') {
      this.plan1Price = 750;
      this.plan2Price = 2000;
      this.planPeriod = 'Monthly';
      this.plan1Offer = 'Save 20% with annual subscription';
      this.plan2Offer = 'Save 20% with annual subscription';
    }
    if (period === 'yearly') {
      this.plan1Price = 7500;
      this.plan2Price = 20000;
      this.planPeriod = 'Yearly';
      this.plan2Offer = '20% Discount Applied';
      this.plan1Offer = '20% Discount Applied';
    }
  }
}
