import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

//stripe
import { StripeSource, StripeToken } from "stripe-angular";

// Angular Material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

// Ngx
import { NgxSpinnerService } from 'ngx-spinner';

// Api ,Common and Auth Service
import { AppService } from '../../services';
import { CheckLoginAuthService } from '../../core/guards';
import { CommonService } from "../../services/common.service";

@Component({
  selector: 'app-company-profile-billing-info',
  templateUrl: './company-profile-billing-info.component.html',
  styleUrls: ['./company-profile-billing-info.component.scss']
})

export class CompanyProfileBillingInfoComponent implements OnInit {

  addUserForm: FormGroup;
  formSubmitted = false;
  showNav = false;
  state: any = [];
  extraData: any = [];
  billingInfo: any = {};
  billingInfoPresent: any = {};

  constructor(private checkLogin: CheckLoginAuthService, private appService: AppService, private commonService: CommonService,
    private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
    public dialog: MatDialog, private router: Router,
    private snackbar: MatSnackBar) { }

  ngOnInit() {
    this.state = this.commonService.getStates();
    this.addUserForm = this.formBuilder.group({
      companyname: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9 ]+$/), Validators.maxLength(80)]],
      email: ['', [Validators.required, Validators.email]],
      addressl1: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9 ]+$/), Validators.maxLength(80)]],
      addressl2: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9 ]+$/), Validators.maxLength(80)]],
      city: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9 ]+$/), Validators.maxLength(80)]],
      zipcode: ['', [Validators.required, Validators.maxLength(30)]],
      state: ['', [Validators.required]],
      phonenumber: ['', [Validators.required, Validators.pattern(/^[0-9]+$/), Validators.maxLength(10)]]
    });
  }

  // Used for checking textfeild is filled or not. if its filled then green tick mark is appeared in nav bar in front of that feild name
  dataEntered() {
    if (this.billingInfo.name !== undefined && this.billingInfo.name.length > 0) {
      this.billingInfoPresent.name = true;
    } else {
      this.billingInfoPresent.name = false;
    }
    if ((this.billingInfo.addressl1 !== undefined && this.billingInfo.addressl1.length > 0)
      && (this.billingInfo.addressl2 !== undefined && this.billingInfo.addressl2.length > 0)
      && (this.billingInfo.city !== undefined && this.billingInfo.city.length > 0)
      && (this.billingInfo.state !== undefined)
      && (this.billingInfo.zipcode !== undefined && this.billingInfo.zipcode.length > 0)) {
      this.billingInfoPresent.address = true;
    } else {
      this.billingInfoPresent.address = false;
    }
    if (this.billingInfo.phonenumber !== undefined && this.billingInfo.phonenumber.length === 10) {
      this.billingInfoPresent.phonenumber = true;
    } else {
      this.billingInfoPresent.phonenumber = false;
    }
    if (this.billingInfo.email !== undefined && this.billingInfo.email.length > 0) {
      this.billingInfoPresent.email = true;
    } else {
      this.billingInfoPresent.email = false;
    }
  }


  // Convenience getter for easy access to form feild
  get f() { return this.addUserForm.controls; }

  // Used for navbar toggler
  showNavFnc() {
    if (this.showNav) {
      this.showNav = false;
    } else {
      this.showNav = true;
    }
  }

  assign() {
    this.extraData = {
      "name": this.addUserForm.value.companyname,
      "email": this.addUserForm.value.email,
      "address_city": this.addUserForm.value.city,
      "address_line1": this.addUserForm.value.addressl1,
      "address_line2": this.addUserForm.value.addressl2,
      "address_state": this.addUserForm.value.state,
      "address_zip": this.addUserForm.value.zipcode,
    }
  }

  onStripeInvalid(error: Error) {
    console.log('Validation Error', error)
  }

  onStripeError(error: Error, token: StripeToken) {
    console.error('Stripe error', token)
  }

  onPay(token: StripeToken) {

    this.formSubmitted = true;
    console.log(this.addUserForm.value);
    if (this.addUserForm.invalid) {
      return;
    }
    else {
      console.log(token);
      let url = 'billing';
      let data = {
        company_name: this.addUserForm.value.companyname,
        company_email: this.addUserForm.value.email,
        company_address_line_1: this.addUserForm.value.addressl1,
        company_address_line_2: this.addUserForm.value.addressl2,
        company_city: this.addUserForm.value.city,
        company_state: this.addUserForm.value.state,
        company_zip: this.addUserForm.value.zipcode,
        token: token.id,
        company_id: '5e7a797468cca60017310d83',
        user_id: '5e7a795768cca60017310d81',
      }

      console.log(data);
      this.appService.POST(url, data).subscribe((res: any) => {
        console.log(res);
        this.snackbar.open('Payment Successfull', '', {
          duration: 3000, verticalPosition: 'top', panelClass: 'success-dialog'
        });
        this.router.navigate(['/company-add-team']);
      },
        err => {
          if (err.status === 500) {
            console.log(err);
            this.snackbar.open(err.error, '', {
              duration: 3000, verticalPosition: 'top', panelClass: 'success-dialog'
            });
          }
        })
    }
  }
}